var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('el-form',{ref:"formData",staticClass:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"inline":true}},[_c('el-form-item',{attrs:{"label":"字典类型","prop":"dictName","rules":{
        required: true,
        trigger: 'blur',
        message: '字典类型不能为空'
      }}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入字典类型","maxlength":"50","show-word-limit":""},model:{value:(_vm.formData.dictName),callback:function ($$v) {_vm.$set(_vm.formData, "dictName", $$v)},expression:"formData.dictName"}})],1),_c('el-form-item',{attrs:{"label":"对应编码","prop":"dictType","rules":{
        required: true,
        trigger: 'blur',
        message: '对应编码不能为空'
      }}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入对应编码","maxlength":"50","show-word-limit":""},model:{value:(_vm.formData.dictType),callback:function ($$v) {_vm.$set(_vm.formData, "dictType", $$v)},expression:"formData.dictType"}})],1),_c('el-form-item',{attrs:{"label":"状态","prop":"status","rules":{ required: true, trigger: 'blur', message: '请选择状态' }}},[_c('el-radio-group',{model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("正常")]),_c('el-radio',{attrs:{"label":0}},[_vm._v("停用")])],1)],1)],1),_c('div',{staticClass:"dialog_submit"},[_c('el-button',{staticClass:"btn btn_blue",on:{"click":function($event){return _vm.submit('formData')}}},[_vm._v("提交")]),_c('el-button',{staticClass:"btn btn_normal",on:{"click":_vm.cancel}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }