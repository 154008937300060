<template>
  <div class="container">
    <header-bar></header-bar>
    <div class="gl_filter bg_white">
      <el-form :inline="true" class="form">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="字典类型">
              <el-input
                v-model="formData.dictName"
                clearable
                placeholder="请输入字典类型"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="对应编码">
              <el-input
                v-model="formData.dictType"
                clearable
                placeholder="请输入对应编码"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button class="btn btn_blue" icon="el-icon-search" @click="search">搜索</el-button>
            <el-button icon="el-icon-plus" class="btn btn_orange" @click="addDict">新建字典</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="字典类型" prop="dictName">
          <template slot-scope="scope">
            <span class="hh_txt color_blue" @click="toDetail(scope.row)">{{ scope.row.dictName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="对应编码" prop="dictType" />
        <el-table-column label="账号状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === '1'">正常</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="operate color_blue" @click="edit(scope)">编辑</span>
            <span class="operate color_red" @click="del(scope)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination :pageSize="formData.pageSize" :total="totalCount" @pageChange="pageChange" ref="child" />
    <el-dialog
      class="dialog_wrapper"
      :title="title"
      v-if="isNewBuild"
      :visible.sync="isNewBuild"
      :close-on-click-modal="false"
      width="30%"
    >
      <add-dictionary :dictionaryId="dictionaryId" @showBuild="showBuild"></add-dictionary>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from '@comps/backStageComponent/pagination'
import headerBar from '@comps/backStageComponent/layout/headerBar/headerBar'
import addDictionary from './components/addDictionary'
import API from '@apis/backStageEndAPI/dictionaryManagementAPI/index'
import CommonUtils from '@utils/index.js'

export default {
  name: 'dictionaryManagement',
  components: {
    headerBar,
    Pagination,
    addDictionary,
  },
  data() {
    return {
      dictionaryId: '',
      totalCount: 0,
      title: '',
      isNewBuild: false,
      formData: {
        dictName: '',
        dictType: '',
        page: 1, //页码
        pageSize: 10, //数据量
      },
      tableData: [],
    }
  },
  mounted() {
    this.getDictionaryList()
  },
  methods: {
    getDictionaryList() {
      API.dictionaryList(CommonUtils.parseToParams(this.formData)).then(res => {
        this.tableData = res.dataList
        this.totalCount = res.rowCount
      })
    },
    addDict() {
      this.isNewBuild = true
      this.title = '新建字典类型'
      this.dictionaryId = ''
    },
    edit(scope) {
      this.isNewBuild = true
      this.title = '编辑字典类型'
      this.dictionaryId = scope.row.dictId
    },
    search() {
      this.formData.page = 1
      this.$refs.child.page = 1
      this.getDictionaryList()
    },
    del(scope) {
      API.delDictionary(scope.row.dictId).then(() => {
        this.formData.page = 1
        this.$refs.child.page = 1
        this.getDictionaryList()
      })
    },
    showBuild(bool) {
      this.isNewBuild = bool
      this.getDictionaryList()
    },
    pageChange(val) {
      //页面跳转
      this.formData.page = val
      this.getDictionaryList()
    },
    toDetail(row) {
      this.$router.push({
        path: '/dictionaryDetail',
        query: {
          dictName: row.dictName,
          dictType: row.dictType,
          dictId: row.dictId,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.gl_filter {
	margin-bottom: 16px;
	padding: 16px;
	border-radius: 12px;
	::v-deep .el-form-item {
		margin-bottom: 0 !important;
	}
}
.hh_txt {
	cursor: pointer;
}
</style>
