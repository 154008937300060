<template>
  <div class="container">
    <el-form ref="formData" class="form" :model="formData" :rules="rules" :inline="true">
      <el-form-item
        label="字典类型"
        prop="dictName"
        :rules="{
          required: true,
          trigger: 'blur',
          message: '字典类型不能为空'
        }"
      >
        <el-input
          v-model="formData.dictName"
          clearable
          placeholder="请输入字典类型"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        label="对应编码"
        prop="dictType"
        :rules="{
          required: true,
          trigger: 'blur',
          message: '对应编码不能为空'
        }"
      >
        <el-input
          v-model="formData.dictType"
          clearable
          placeholder="请输入对应编码"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status" :rules="{ required: true, trigger: 'blur', message: '请选择状态' }">
        <el-radio-group v-model="formData.status">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="dialog_submit">
      <el-button class="btn btn_blue" @click="submit('formData')">提交</el-button>
      <el-button class="btn btn_normal" @click="cancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import API from '@apis/backStageEndAPI/dictionaryManagementAPI';
export default {
  name: 'addDictionary',
  props: ['dictionaryId'],
  data() {
    return {
      formData: {
        dictId: '',
        dictName: '',
        dictType: '',
        status: 1
      },
      rules: {}
    };
  },
  mounted() {
    if (this.dictionaryId) {
      this.getDictionaryInfo();
    }
  },
  methods: {
    getDictionaryInfo() {
      API.getDictionaryInfo(this.dictionaryId).then((res) => {
        this.formData.dictName = res.dictName;
        this.formData.dictType = res.dictType;
        this.formData.status = Number(res.status);
      });
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dictionaryId) {
            this.formData.dictId = this.dictionaryId;
            API.editDictionary(this.formData).then((res) => {
              this.$emit('showBuild', false);
            });
          } else {
            API.newBuildDictionary(this.formData).then((res) => {
              this.$emit('showBuild', false);
            });
          }
        }
      });
    },
    cancel() {
      this.$emit('showBuild', false);
    }
  }
};
</script>
<style lang="scss" scoped></style>
